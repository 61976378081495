import React, { useState, useEffect } from "react"
import Nav from "../components/Nav/Nav";
import Footer from "../components/Footer/Footer";
import Cookie from "../components/Cookie/Cookie";
import Advert from "../components/Advert/Advert";
// import Layout from "../components/Layout/Layout";
import PassGenerator from "../components/Form/PassGenerator";
// import AniLink from "gatsby-plugin-transition-link/AniLink";
import { Helmet } from "react-helmet";
// mport CookieBanner from 'react-cookie-banner';
import { METADATA, BRANDNAME } from '../constants';
import bg from "../assets/char-bg.jpg";
import main from "../assets/passnip-illustration-a.svg";
import main2 from "../assets/passnip-illustration-b.svg";
import main3 from "../assets/passnip-illustration-c.svg";
import divide1 from "../assets/divide-1.svg";
import divide2 from "../assets/divide-2.svg";
import divide3 from "../assets/divide-3.svg";
import socialbanner from "../assets/passnip-social-banner.jpg";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Collapse } from 'react-collapse';
import '../styles/styles.css';
export default function Home() {
  const [accordian1, setAccordian1] = useState(false);
  const [accordian2, setAccordian2] = useState(false);
  const [accordian3, setAccordian3] = useState(false);
  const [accordian4, setAccordian4] = useState(false);
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false
  };
  const logos = ['netflix', 'xbox', 'instagram', 'adobe', 'facebook', 'playstation', 'twitter', 'microsoft', 'epicgames', 'spotify', 'riotgames', 'uber', 'tiktok', 'steam', 'wordpress']
  return (
    <React.Fragment>
      <Helmet>
       <title>{METADATA.sitetitle}</title>
       <meta name="viewport" content="initial-scale=1.0, width=device-width" />
       <meta httpEquiv="Content-Language" content={METADATA.lang} />
       <meta property="og:site_name" content={BRANDNAME} />
       <meta property="og:url" content={METADATA.url} />
       <meta property="og:type" content="website" />
       <meta property="title" content={BRANDNAME} />
       <meta property="og:title" content={BRANDNAME} />
       <meta property="og:description" content={METADATA.description} />
       <meta property="og:image" content={socialbanner}/>
       <meta name="description" content={METADATA.description} /><meta charSet="utf-8" />
       <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      </Helmet>
      <Advert />
      <div className="bg-white p-2 flex flex-col">
        <div className="bg-color main-wrap flex-1 flex flex-col p4 sm:p-8 rounded-lg sm:rounded-2xl" id="top">
          <Nav />
          <div className="lg:flex flex-1 lg:flex-row-reverse">
            <div className="w-full lg:w-1/2 max-w-4xl mt-4 sm:mt-10 mx-auto flex items-center relative">
              
              <svg className="ani-slow w-6 h-6 sm:w-16 sm:h-16 absolute" style={{ top: '10%', left: '5%'}} viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 27C0 26.9159 0.0642861 26.8455 0.147907 26.8368C14.2023 25.3713 25.3713 14.2023 26.8368 0.147904C26.8455 0.0642832 26.9159 0 27 0V0C27.0841 0 27.1545 0.0642861 27.1632 0.147907C28.6287 14.2023 39.7977 25.3713 53.8521 26.8368C53.9357 26.8455 54 26.9159 54 27V27C54 27.0841 53.9357 27.1545 53.8521 27.1632C39.7977 28.6287 28.6287 39.7977 27.1632 53.8521C27.1545 53.9357 27.0841 54 27 54V54C26.9159 54 26.8455 53.9357 26.8368 53.8521C25.3713 39.7977 14.2023 28.6287 0.147904 27.1632C0.0642832 27.1545 0 27.0841 0 27V27Z" fill="#2C7063"/>
              </svg>
              <svg className="ani-mid w-6 h-6 sm:w-16 sm:h-16 absolute" style={{ top: '-5%', right: '15%'}} viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 27C0 26.9159 0.0642861 26.8455 0.147907 26.8368C14.2023 25.3713 25.3713 14.2023 26.8368 0.147904C26.8455 0.0642832 26.9159 0 27 0V0C27.0841 0 27.1545 0.0642861 27.1632 0.147907C28.6287 14.2023 39.7977 25.3713 53.8521 26.8368C53.9357 26.8455 54 26.9159 54 27V27C54 27.0841 53.9357 27.1545 53.8521 27.1632C39.7977 28.6287 28.6287 39.7977 27.1632 53.8521C27.1545 53.9357 27.0841 54 27 54V54C26.9159 54 26.8455 53.9357 26.8368 53.8521C25.3713 39.7977 14.2023 28.6287 0.147904 27.1632C0.0642832 27.1545 0 27.0841 0 27V27Z" fill="#F06F6F"/>
              </svg>
              <svg className="ani-fast w-3 h-3 sm:w-6 sm:h-6 absolute bottom-0"  style={{ right: '20%', bottom: '20%'}} viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M27 44C36.3888 44 44 36.3888 44 27C44 17.6112 36.3888 10 27 10C17.6112 10 10 17.6112 10 27C10 36.3888 17.6112 44 27 44ZM27 54C41.9117 54 54 41.9117 54 27C54 12.0883 41.9117 0 27 0C12.0883 0 0 12.0883 0 27C0 41.9117 12.0883 54 27 54Z" fill="#F5C396"/>
              </svg>
              <svg className="ani2-slow w-3 h-3 sm:w-6 sm:h-6 absolute" style={{ bottom: '30%', left: '-20%'}} viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
               <circle cx="27" cy="27" r="27" fill="#7D88EF"/>
              </svg>

              <img alt="Character remembering their password" src={main} className="mx-auto w-48 sm:w-56 lg:w-2/3" />
            </div>
            <div className="w-full lg:w-1/2 max-w-4xl mt-4 sm:mt-10 mx-auto flex items-center">
              <div>
                <div className="p-4">
                    <p className="text-sm sm:text-lg mono-font tracking-widest" style={{ color: '#2C7063'}}>GENERATE</p>
                    <h1 className="text-3xl sm:text-4xl mb-4 mono-font sm:pr-16">Secure passwords that you'll remember.</h1>
                    <p className="text-sm sm:text-lg mb-8 raleway-font">Generate a random passphrase you can remember in your browser, learn more <a href="#about"><span className="underline">here</span></a></p>
                </div>
                <PassGenerator />
              </div>
            </div>
          </div>
          <div className="my-20 sm:-mx-8">
            <p className="text-sm sm:text-lg mono-font tracking-widest ml-2 sm:ml-12" style={{ color: '#2C7063'}}>PERFECT FOR:</p>
            <br /><br />
            <Slider {...settings}>
                {logos.map((logo) => (
                  <img className="w-6 h-6 sm:w-12 sm:h-12" src={`/logos/${logo}.svg`} alt="Logo" />
                ))}
            </Slider>
          </div>
        </div>
      </div>
      <div className="bg-white p-2 pt-0 flex flex-col relative">
        <svg className="w-6 h-6 sm:w-12 sm:h-12 absolute ani-fast" style={{ top: '25%', left: '5%'}} viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0 27C0 26.9159 0.0642861 26.8455 0.147907 26.8368C14.2023 25.3713 25.3713 14.2023 26.8368 0.147904C26.8455 0.0642832 26.9159 0 27 0V0C27.0841 0 27.1545 0.0642861 27.1632 0.147907C28.6287 14.2023 39.7977 25.3713 53.8521 26.8368C53.9357 26.8455 54 26.9159 54 27V27C54 27.0841 53.9357 27.1545 53.8521 27.1632C39.7977 28.6287 28.6287 39.7977 27.1632 53.8521C27.1545 53.9357 27.0841 54 27 54V54C26.9159 54 26.8455 53.9357 26.8368 53.8521C25.3713 39.7977 14.2023 28.6287 0.147904 27.1632C0.0642832 27.1545 0 27.0841 0 27V27Z" fill="#F5C396"/>
        </svg>
        <svg className="w-3 h-3 sm:w-6 sm:h-6 absolute bottom-0 ani2-mid"  style={{ right: '10%', top: '10%'}} viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M27 44C36.3888 44 44 36.3888 44 27C44 17.6112 36.3888 10 27 10C17.6112 10 10 17.6112 10 27C10 36.3888 17.6112 44 27 44ZM27 54C41.9117 54 54 41.9117 54 27C54 12.0883 41.9117 0 27 0C12.0883 0 0 12.0883 0 27C0 41.9117 12.0883 54 27 54Z" fill="#7EE0B2"/>
        </svg>
        <svg className="w-24 h-24 sm:w-32 sm:h-32 absolute bottom-0" style={{ left: '10%', bottom: '10%'}} viewBox="0 0 146 153" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path fill-rule="evenodd" clip-rule="evenodd" d="M67.4899 66.1342C50.7012 64.1019 35.6275 68.1897 27.8947 73.1488C20.2398 78.0578 16.5573 83.6191 15.3954 88.9099C14.228 94.2256 15.5059 99.7162 18.6281 104.654C24.9545 114.66 38.3843 121.66 51.4594 119.252C63.0312 117.12 69.8249 111.32 72.8635 103.612C75.9715 95.727 75.3812 85.262 70.8274 73.5473C69.8804 71.1111 68.7669 68.6352 67.4899 66.1342ZM63.9207 59.7768C47.501 58.6873 32.8886 62.8183 24.6557 68.0981C15.9772 73.6637 11.1103 80.45 9.53502 87.6229C7.96526 94.7709 9.76264 101.86 13.5567 107.861C21.0631 119.733 36.8304 128.048 52.5464 125.153C65.7905 122.713 74.5209 115.768 78.4455 105.812C82.3008 96.0315 81.2935 83.9111 76.4197 71.3734C75.9065 70.053 75.349 68.7244 74.748 67.3894C78.1245 68.1529 81.5365 69.1823 84.941 70.5174C107.822 79.4906 130.867 102.512 140.009 152.963L145.912 151.893C136.486 99.8742 112.363 74.8267 87.1316 64.9316C81.8551 62.8623 76.5517 61.466 71.3555 60.6234C59.1639 38.5491 35.5339 15.609 2.56764 1.70937e-06L6.46668e-08 5.42285C30.1318 19.6898 51.8844 40.1137 63.9207 59.7768Z" fill="#F06F6F"/>
        </svg>

        <svg className="ani-slow w-3 h-3 sm:w-6 sm:h-6 absolute" style={{ bottom: '25%', right: '20%'}} viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
               <circle cx="27" cy="27" r="27" fill="#7D88EF"/>
        </svg>

        <div className="main-wrap flex-1 flex flex-col bg-contain sm:bg-cover bg-dark rounded-lg sm:rounded-2xl">
          <div className="w-full max-w-4xl mt-10 mx-auto">
            <div className="p-4 text-white my-32 sm:my-48">
                <h1 className="text-3xl mb-4 mono-font text-center"><span className="mono-font">"</span>Through 20 years of effort, we've successfully trained everyone to use passwords that are hard for humans to remember, but easy for computers to guess.<span className="mono-font">"</span></h1>
                <p className="mono-font text-center">- XKDC</p>
                <img alt="Evil computer illustration" src={main3} className="mx-auto w-40 sm:w-48 mt-16" />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white p-2 pt-0 ">
        <div className="p-4 sm:p-8  min-h-screen flex flex-col relative bg-peach rounded-lg sm:rounded-2xl" id="about">
          <div className="main-wrap flex-1 flex flex-col my-24">
            <div className="lg:flex flex-1">
            <div className="w-full lg:w-1/2 max-w-4xl mt-4 sm:mt-10 mx-auto flex items-center relative">
              <img alt="Character forgetting their password" src={main2} className="mx-auto w-64 lg:w-2/3" />

            <svg className="ani-slow w-8 h-8 absolute bottom-0"  style={{ right: '5%', top: '20%'}} viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0 27C0 26.9159 0.0642861 26.8455 0.147907 26.8368C14.2023 25.3713 25.3713 14.2023 26.8368 0.147904C26.8455 0.0642832 26.9159 0 27 0V0C27.0841 0 27.1545 0.0642861 27.1632 0.147907C28.6287 14.2023 39.7977 25.3713 53.8521 26.8368C53.9357 26.8455 54 26.9159 54 27V27C54 27.0841 53.9357 27.1545 53.8521 27.1632C39.7977 28.6287 28.6287 39.7977 27.1632 53.8521C27.1545 53.9357 27.0841 54 27 54V54C26.9159 54 26.8455 53.9357 26.8368 53.8521C25.3713 39.7977 14.2023 28.6287 0.147904 27.1632C0.0642832 27.1545 0 27.0841 0 27V27Z" fill="#7EE0B2"/>
            </svg>
            <svg className="w-3 h-3 sm:w-6 sm:h-6 absolute bottom-0 ani2-mid"  style={{ left: '10%', top: '80%'}} viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M27 44C36.3888 44 44 36.3888 44 27C44 17.6112 36.3888 10 27 10C17.6112 10 10 17.6112 10 27C10 36.3888 17.6112 44 27 44ZM27 54C41.9117 54 54 41.9117 54 27C54 12.0883 41.9117 0 27 0C12.0883 0 0 12.0883 0 27C0 41.9117 12.0883 54 27 54Z" fill="#F06F6F"/>
            </svg>
            </div>
            <div className="w-full lg:w-1/2 max-w-4xl mt-4 sm:mt-10 mx-auto flex items-center">
              <div>
                <div className="p-4">
                    <p className="text-sm sm:text-lg mono-font tracking-widest" style={{ color: '#F06F6F'}}>ABOUT</p>
                    <h1 className="text-4xl mb-4 mono-font"><strong>Stop forgetting that f**king password</strong></h1>
                    <p className="text-sm sm:text-lg mb-8 raleway-font">Passnip is simple online tool to generate memorable, yet secure passphrases which are easier to recall than a random string.</p>
                    <p className="text-sm sm:text-lg mb-8 raleway-font">Words are joined with a dash, and you can choose to capitalize the words & apend a random 4 digit string to the end to fufil password requirements.</p>
                    <p className="text-sm sm:text-lg mb-8 raleway-fontt"><a href="https://xkcd.com/936/" className="underline" target="=blank">Why use a memorable password?</a></p>
                    <p className="text-sm sm:text-lg mb-8 raleway-font">All passphrases generated are done so in your browser, without anything being sent to our server.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
      <div className="bg-white p-2 pt-0 flex flex-col relative">
            <svg className="ani2-mid w-3 h-3 sm:w-8 sm:h-8 absolute bottom-0"  style={{ right: '24%', top: '200px'}} viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M27 44C36.3888 44 44 36.3888 44 27C44 17.6112 36.3888 10 27 10C17.6112 10 10 17.6112 10 27C10 36.3888 17.6112 44 27 44ZM27 54C41.9117 54 54 41.9117 54 27C54 12.0883 41.9117 0 27 0C12.0883 0 0 12.0883 0 27C0 41.9117 12.0883 54 27 54Z" fill="#2C7063"/>
            </svg>
            <svg className="ani-slow w-3 h-3 sm:w-8 sm:h-8 absolute bottom-0"  style={{ left: '22%', top: '380px'}} viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0 27C0 26.9159 0.0642861 26.8455 0.147907 26.8368C14.2023 25.3713 25.3713 14.2023 26.8368 0.147904C26.8455 0.0642832 26.9159 0 27 0V0C27.0841 0 27.1545 0.0642861 27.1632 0.147907C28.6287 14.2023 39.7977 25.3713 53.8521 26.8368C53.9357 26.8455 54 26.9159 54 27V27C54 27.0841 53.9357 27.1545 53.8521 27.1632C39.7977 28.6287 28.6287 39.7977 27.1632 53.8521C27.1545 53.9357 27.0841 54 27 54V54C26.9159 54 26.8455 53.9357 26.8368 53.8521C25.3713 39.7977 14.2023 28.6287 0.147904 27.1632C0.0642832 27.1545 0 27.0841 0 27V27Z" fill="#F06F6F"/>
            </svg>
        <div className="main-wrap flex-1 flex flex-col p-4 sm:p-8 bg-contain sm:bg-cover bg-color rounded-lg sm:rounded-2xl">
        <div className="max-w-3xl mx-auto my-24 relative">
            <p className="text-sm sm:text-lg mono-font tracking-widest text-center" style={{ color: '#2C7063'}}>FAQ</p>
            <h1 className="text-4xl mono-font text-center mb-8"><strong>Questions answered</strong></h1>
            <div className="relative rounded-lg overflow-hidden border shadow-md hover:shadow-lg transition duration-500 ease-in-out mb-24">
              <div className="bg-white bg-opacity-50">
                <button
                  className="transition duration-500 ease-in-out border-t passnip-faq border-white focus:outline-none btn  p-4 w-full text-left mono-font sm:text-lg"
                  type="checkbox"
                  onClick={() => {
                  setAccordian1(!accordian1);
                }}
                >
                  {accordian1 ? '-' : '+'} Is Passnip safe to use?
                </button>
                <Collapse isOpened={accordian1}>
                  <div className="p-4">
                    <p className="raleway-font text-sm mb-4">Absolutely! All passwords you create with the Passnip are generated locally, in your browser on your computer. They are not saved or shared anywhere, even Passnip can't see your password.</p>
                    <p className="raleway-font text-sm">Just be sure to close this page once you’ve generated and copied your password to prevent anyone else from seeing it if they use your computer.</p>
                  </div>
                </Collapse>
              </div>
              <div className="bg-white bg-opacity-50">
                <button
                  className="transition duration-500 ease-in-out passnip-faq border-t border-white focus:outline-none btn p-4 w-full text-left mono-font sm:text-lg"
                  type="checkbox"
                  onClick={() => {
                  setAccordian2(!accordian2);
                }}
                >
                  {accordian2 ? '-' : '+'} Do I need a unique password for every account?
                </button>
                <Collapse isOpened={accordian2}>
                  <div className="p-4">
                    <p className="raleway-font text-sm mb-4">100%, Using the same password across multiple accounts is a huge no-no. If hackers learn the password to one of your accounts, they’ll have your password for all the others, too. So make sure you use a unique password for each account.</p>
                  </div>
                </Collapse>
              </div>
              <div className="bg-white bg-opacity-50">
                <button
                  className="transition duration-500 ease-in-out passnip-faq border-t border-white focus:outline-none btn p-4 w-full text-left border-b border-white mono-font sm:text-lg"
                  type="checkbox"
                  onClick={() => {
                  setAccordian4(!accordian4);
                }}
                >
                  {accordian4 ? '-' : '+'} Random password vs. memorable passwords?
                </button>
                <Collapse isOpened={accordian4}>
                  <div className="p-4">
                    <p className="raleway-font text-sm mb-4">Memorable passwords or 'passphrases', are typically a combination of 3-6 words strung together into a mnemonic device. Because passphrases are often over 20 characters or longer, they are extremely resilient to brute force attacks, but also easy to recall compared to a random string of 20 characters.</p>
                  </div>
                </Collapse>
              </div>
              <div className="bg-white bg-opacity-50">
                <button
                  className="transition duration-500 ease-in-out passnip-faq border-t border-white focus:outline-none btn p-4 w-full mono-font text-left sm:text-lg"
                  type="checkbox"
                  onClick={() => {
                  setAccordian3(!accordian3);
                }}
                >
                  {accordian3 ? '-' : '+'} I have a some feedback / enquiry
                </button>
                <Collapse isOpened={accordian3}>
                  <div className="p-4">
                    <p className="raleway-font text-sm">We are always open ears to feedback and questions! <a className="underline" href="mailto:hello@passnip.com">Send us an email</a> and we'll take a gander.</p>
                  </div>
                </Collapse>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  )
}
