export const BRANDNAME = 'Passnip';

export const METADATA = {
  title: BRANDNAME,
  url: 'https://passnip.com',
  type: 'site',
  name: 'Passnip',
  image: 'https://images.prismic.io/homebery/6ee894bd-0c2f-4f24-801f-dac7f96de125_luxury-tiny-house.jpg?auto=compress,format&rect=0,0,4200,2800&w=720&h=480',
  sitetitle: 'Passnip | Secure & memorable passwords',
  description: 'Stop forgetting that f**king password, generate a random secure passphrase you can remember instantly in your browser.',
};
